


























































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import {
  INTERVIEW_LISTING,
  INTERVIEW_SEARCHED_JOB
} from "@/store/modules/recruiter/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { GET_JOB_BY_ID } from "@/store/modules/common/constants";
import moment from "moment";
import { CandidateInterviewsListing } from "@/store/modules/candidates/interfaces";
import {
  CANDIDATE_INTERVIEW_LISTING,
  CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING,
  CANDIDATE_INTERVIEWS,
  INTERVIEW_LISTING_PAGINATION
} from "@/store/modules/candidates/constants";
import {
  get_interview_status_class,
  get_interview_status_msg
} from "@/utils/interviews";
import { Interview } from "@/interfaces/responses/interviews/interviews";
import { InterviewListingPayload } from "@/store/modules/recruiter/interfaces";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "CandidateInterviewListingBody",
  components: {
    DataNotFound
  },
  data() {
    return {
      loading: true,
      total_count: 0
    };
  },
  watch: {
    get_searched_job(n) {
      if (n) {
        this.process_searched_job();
      } else
        this.process_interview_listing(this.candidate_interviews.pagination);
    }
  },
  computed: {
    Interview() {
      return Interview;
    },
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS
    }),
    ...mapGetters("candidate", {
      get_interview_listing: CANDIDATE_INTERVIEW_LISTING,
      search_job_loading: CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING,
      candidate_interviews: CANDIDATE_INTERVIEWS
    }),
    ...mapGetters("recruiter", {
      get_searched_job: INTERVIEW_SEARCHED_JOB
    }),
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  async mounted() {
    if (this.get_searched_job) await this.process_searched_job();
    else
      await this.process_interview_listing(
        this.candidate_interviews.pagination
      );
  },
  methods: {
    ...mapActions("recruiter", {
      fetch_interview_listing: INTERVIEW_LISTING
    }),
    ...mapActions("common", {
      fetch_job_by_id: GET_JOB_BY_ID
    }),
    ...mapMutations("candidate", {
      set_interview_listing: CANDIDATE_INTERVIEW_LISTING,
      set_search_job_loading: CANDIDATE_INTERVIEW_SEARCH_JOB_LOADING,
      set_pagination: INTERVIEW_LISTING_PAGINATION
    }),
    async process_interview_listing(page = 1, limit = 10) {
      this.loading = true;
      const res = await this.fetch_interview_listing({
        page: page - 1,
        limit: limit,
        candidate_id: this.get_user.id
      });
      if (res) {
        this.total_count = res.total;
        const finalObj: CandidateInterviewsListing[] = [];
        for (let interview of res.results) {
          finalObj.push({
            interview_id: interview.id,
            date: moment(interview.created_at).format("LL"),
            job_id: interview.job_id,
            job_title: interview.job_title,
            status: interview.interview_status,
            class: get_interview_status_class(interview.interview_status),
            readable_status: get_interview_status_msg(
              interview.interview_status,
              this.get_user.role_id
            )
          });
        }
        this.set_interview_listing(finalObj);
      } else await this.$router.push("/candidate/dashboard");
      this.loading = false;
    },
    async process_searched_job() {
      this.set_pagination(1);
      this.set_interview_listing(null);
      this.total_count = 0;
      const searched_job = this.get_searched_job;
      const finalObj: CandidateInterviewsListing[] = [];
      const payload: InterviewListingPayload = {
        candidate_id: this.get_user.id,
        job_id: searched_job.job_id
      };
      const interview = await this.fetch_interview_listing(payload);
      if (interview.length > 0) {
        finalObj.push({
          interview_id: interview[0].id,
          date: moment(interview[0].created_at).format("LL"),
          job_id: interview[0].job_id,
          job_title: interview[0].job_title,
          status: interview[0].interview_status,
          class: get_interview_status_class(interview[0].interview_status),
          readable_status: get_interview_status_msg(
            interview[0].interview_status,
            this.get_user.role_id
          )
        });
      }
      this.set_interview_listing(finalObj);
      this.set_search_job_loading(false);
      this.loading = false;
    },

    async start_interview(interview: CandidateInterviewsListing) {
      await this.$router.push(
        `/candidate/interviews/start/${interview.interview_id}`
      );
    }
  }
});
